<template>
  <main>
    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">BARANG</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <v-text-field
              v-model="search"
              class="pt-5"
              label="Search"
              dense
              outlined
              @keyup="doSearch()"
            ></v-text-field>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase">NO</th>
                    <th class="text-center text-uppercase">Kode Barang</th>
                    <th class="text-center text-uppercase">Nama Barang</th>
                    <th class="text-center text-uppercase">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in displayData()" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="text-center">
                      {{ data.kode }}
                    </td>
                    <td class="text-center">
                      {{ data.nama_barang }}
                    </td>
                    <td class="text-center">
                      <v-btn
                        data-bs-dismiss="modal"
                        color="primary"
                        class="me-3 mt-4"
                        @click="CreatePembelianModal(data.kode)"
                      >
                        <v-icon v-if="data.status_beli == 0" color="#ECEFF1">
                          {{ icons.mdiCheckBold }}
                        </v-icon>
                        <v-icon v-if="data.status_beli == 1" color="#FFDD00">
                          {{ icons.mdiCheckBold }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <center>
                        <div class="v-data-footer">
                          <span class="v-data-footer__icons-before" bis_skin_checked="1">
                            <button
                              type="button"
                              :class="[
                                'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page == 1 ? 'v-btn--disabled' : '',
                              ]"
                              aria-label="Previous page"
                              @click="page--"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                          <span
                            v-for="pageNumber in pages.slice(page - 1, page + 5)"
                            :key="pageNumber"
                            :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                            @click="page = pageNumber"
                          >
                            <button
                              type="button"
                              class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                              aria-label="Next page"
                            >
                              {{ pageNumber }}
                            </button>
                          </span>
                          <span class="v-data-footer__icons-after" bis_skin_checked="1">
                            <button
                              type="button"
                              :class="[
                                'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page < pages.length ? '' : 'v-btn--disabled',
                              ]"
                              aria-label="Next page"
                              @click="page++"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                        </div>
                      </center>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
    <!-- end Modal -->

    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat>
      <v-row>
        <v-col cols="6" sm="6" md="2">
          <v-radio-group style="padding-left: 15px" v-model="pembelian.status_transaksi" column>
            <v-radio label="Cash" color="primary" value="C" checked></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6" sm="6" md="2">
          <v-radio-group v-model="pembelian.status_transaksi" column>
            <v-radio label="Tempo" color="error" value="H"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6" sm="6" md="2">
          <v-radio-group v-model="pembelian.status_transaksi" column>
            <v-radio label="Konsinasi" color="danger" value="K"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" md="5">
          <v-text-field style="padding: 15px" v-model="invoices" label="Invoice" required></v-text-field>
        </v-col>
        <v-col cols="4" sm="6" md="5">
          <v-text-field
            @keyup.enter="CreatePembelianTemp()"
            v-model="pembelian.kode"
            label="Kode Barang / Scan Barcode"
            autofocus
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-row align="center" justify="center">
            <v-btn color="primary" class="me-2 mt-4">
              <a data-bs-toggle="modal" data-bs-target="#exampleModal">
                <v-icon color="#ECEFF1">
                  {{ icons.mdiArchiveSearchOutline }}
                </v-icon>
              </a>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined tile>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 5px" class="text-uppercase">NO</th>
              <th class="text-center text-uppercase">KODE</th>
              <th class="text-center text-uppercase">NAMA BARANG</th>
              <th style="width: 80px" class="text-center text-uppercase">JUMLAH</th>
              <th style="width: 130px" class="text-center text-uppercase">HARGA LAMA</th>
              <th style="width: 130px" class="text-center text-uppercase">HARGA</th>
              <th style="width: 130px" class="text-center text-uppercase">HARGA PPN</th>
              <th style="width: 100px" class="text-center text-uppercase">PPN (%)</th>
              <th class="text-center text-uppercase">SUB TOTAL</th>
              <th class="text-center text-uppercase">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in transaksibelitemps" :key="index">
              <td>1</td>
              <td class="text-center">{{ data.kode }}</td>
              <td class="text-center">{{ data.nama_barang }}</td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  v-model="data.jumlah"
                  @keyup.enter.prevent="
                    isNumber($event)
                    UpdatePembelian(data.id_transaksi_pembeliantemp, index)
                  "
                  solo
                ></v-text-field>
              </td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  @keyup.enter.prevent="
                    isNumber($event)
                    UpdatePembelian(data.id_transaksi_pembeliantemp, index)
                  "
                  v-model="data.harga_beli_lama"
                  solo
                  readonly
                ></v-text-field>
              </td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  @keyup.enter.prevent="
                    isNumber($event)
                    UpdatePembelian(data.id_transaksi_pembeliantemp, index)
                  "
                  v-model="data.harga_beli"
                  solo
                ></v-text-field>
              </td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  @keyup.enter.prevent="
                    isNumber($event)
                    UpdatePembelian(data.id_transaksi_pembeliantemp, index)
                  "
                  v-model="data.harga_beli_ppn"
                  solo
                  readonly
                ></v-text-field>
              </td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  label="0"
                  @keyup.enter.prevent="
                    isNumber($event)
                    UpdatePembelian(data.id_transaksi_pembeliantemp, index)
                  "
                  v-model="data.ppn"
                  solo
                ></v-text-field>
              </td>
              <td class="text-center">
                {{ data.sub_total_pembelian | rupiah }}
                <input type="hidden" label="0" v-model="data.sub_total_pembelian" />
              </td>
              <td class="text-center">
                <v-btn
                  @click="deletePost(data.id_transaksi_pembeliantemp, index)"
                  color="error"
                  class="mt-4"
                  type="button"
                >
                  <v-icon color="#ECEFF1">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td style="font-size: 20px; font-weight: bold">Total</td>
              <td style="font-size: 20px; font-weight: bold" colspan="2" class="text-right">
                {{ totaltransaksis | rupiah }}
                <input type="hidden" v-model="totaltransaksis" />
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td style="font-size: 20px; font-weight: bold">Bayar</td>
              <td style="width: 150px; padding-top: 15px" class="text-center" colspan="2">
                <v-text-field
                  @keyup.enter.prevent="
                    isNumber($event)
                    getBayar()
                  "
                  v-model="bayar"
                  solo
                  clearable
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td style="font-size: 20px; font-weight: bold">Kembali</td>
              <td style="font-size: 20px; font-weight: bold" class="text-right" colspan="2">
                {{ kembalians }}
              </td>
            </tr>
          </tbody>
        </template> </v-simple-table
      ><br /><br />
      <v-col align="right" justify="center" cols="12" md="12">
        <v-btn @click="CreatePembelian()" type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>
        <v-btn @click="deleteAllPost()" color="error" class="mt-4" type="button"> Cancel </v-btn> </v-col
      ><br />
    </v-card>
  </main>
</template>
<script>
import {
  mdiCheckBold,
  mdiCalendarMonth,
  mdiCalendarCheck,
  mdiAccountPlus,
  mdiAccountCheck,
  mdiEmailOutline,
  mdiCellphone,
  mdiCamera,
  mdiSlashForward,
  mdiArchiveSearchOutline,
  mdiDelete,
} from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/superadmin/dashboard',
        },
        {
          text: 'Transaksi Pembelian',
          disabled: false,
          href: '/superadmin/transaksipembelian',
        },
        {
          text: 'Create Transaksi Pembelian',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiCalendarMonth,
        mdiCalendarCheck,
        mdiAccountPlus,
        mdiAccountCheck,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
        mdiArchiveSearchOutline,
        mdiDelete,
      },

      pembelian: {
        status_transaksi: 'C',
        kode: '',
      },
      // ppn: [],
      data: {
        jumlah: '',
        harga_beli_lama: '',
        harga_beli: '',
        harga_beli_ppn: '',
        ppn: '',
        sub_total_pembelian: '',
      },

      bayar: '',
      kembalian: '',
      kembalians: '',

      invoices: '',
      totaltransaksis: [],

      transaksibelitemps: [],
      barang: [],

      errors: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  created() {
    this.$isLoading(true)
    this.getBarangPembelian()
    this.getAllData()
    this.getInvoice()
    this.getTotal()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },

  methods: {
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      }
    },
    resetAll() {
      this.pembelian.kode = ''
      this.bayar = ''
    },

    getBarangPembelian() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/superadmin/barang-pembelian'
      return this.axios.get(url).then(response => {
        this.barang = response.data.barangs
      })
    },

    // getAllBarang() {
    //   //state token
    //   localStorage.getItem('token')

    //   let url = process.env.VUE_APP_ROOT_API + '/api/superadmin/barang-index'
    //   return this.axios.get(url).then(response => {
    //     this.barang = response.data.barangs
    //   })
    // },

    getBayar() {
      console.log('BAYAR ' + this.bayar)
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/pembeliantemp/bayar'
      this.axios
        .post(uri, {
          bayar: this.bayar,
        })
        .then(response => {
          this.kembalians = response.data.kembalian
          setTimeout(() => {
            this.getAllData()
            this.resetAll()
          }, 2000)
        })
    },

    getTotal() {
      localStorage.getItem('token')
      let UriPembelian = process.env.VUE_APP_ROOT_API + '/api/superadmin/pembeliantemp/total'
      return this.axios.get(UriPembelian).then(response => {
        this.totaltransaksis = response.data.totaltransaksi
      })
    },

    getInvoice() {
      localStorage.getItem('token')
      let UriPembelian = process.env.VUE_APP_ROOT_API + '/api/superadmin/pembeliantemp/invoice'
      return this.axios.get(UriPembelian).then(response => {
        this.invoices = response.data.invoice
      })
    },
    getAllData() {
      localStorage.getItem('token')
      let UriPembelian = process.env.VUE_APP_ROOT_API + '/api/superadmin/pembeliantemp-index'
      return this.axios.get(UriPembelian).then(response => {
        this.transaksibelitemps = response.data.transaksibelitemp
      })
    },
    CreatePembelianModal(kode) {
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/pembeliantemp/store'
      this.axios
        .post(uri, {
          kode: kode,
        })
        .then(response => {
          this.$isLoading(true)
          setTimeout(() => {
            this.getAllData()
            this.resetAll()
            this.$isLoading(false)
          }, 2000)
        })
        .catch(e => {
          this.$swal.fire({
            title: 'Error!',
            html: 'Produk sudah diinput !',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          })
        })
    },

    CreatePembelianTemp() {
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/pembeliantemp/store'
      this.axios
        .post(uri, this.pembelian)
        .then(response => {
          setTimeout(() => {
            this.getAllData()
            this.resetAll()
          }, 2000)
        })
        .catch(e => {
          this.$swal.fire({
            title: 'Error!',
            html: 'Produk sudah diinput !',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          })
        })
    },

    CreatePembelian() {
      // console.log('Harga beli ' + this.data.harga_beli)
      const idBarang = this.transaksibelitemps.map(nama_barang => {
        return nama_barang.id_barang
      })

      const hargabeli = this.transaksibelitemps.map(harga_beli => {
        return harga_beli.harga_beli
      })

      const hargabelippn = this.transaksibelitemps.map(harga_beli_ppn => {
        return harga_beli_ppn.harga_beli_ppn
      })

      const jumlah = this.transaksibelitemps.map(jumlah => {
        return jumlah.jumlah
      })

      const ppn = this.transaksibelitemps.map(ppn => {
        return ppn.ppn
      })

      const subtotal = this.transaksibelitemps.map(subtotals => {
        return subtotals.sub_total_pembelian
      })

      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/pembelian/store'
      this.axios
        .post(uri, {
          id_barang: idBarang,
          invoice: this.invoices,
          status_transaksi: this.pembelian.status_transaksi,
          harga_beli: hargabeli,
          harga_beli_ppn: hargabelippn,
          jumlah: jumlah,
          ppn: ppn,
          sub_total_pembelian: subtotal,
          total: this.totaltransaksis,
        })
        .then(response => {
          this.$swal.fire({
            title: 'Success!',
            text: 'Transaksi pembelian successfully!',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          })
          setTimeout(() => {
            this.totaltransaksis = ''
            this.getAllData()
            this.resetAll()
          }, 2000)
        })
    },

    UpdatePembelian(id, index) {
      if (
        this.transaksibelitemps[index].ppn == null ||
        this.transaksibelitemps[index].ppn == '' ||
        this.transaksibelitemps[index].ppn == 0
      ) {
        console.log('KOSONG')
        let ppns = 0
        this.transaksibelitemps.push({ jumlah: '', harga_beli: '', harga_beli_ppn: '' })
        let uriUpdatePembelian = process.env.VUE_APP_ROOT_API + `/api/superadmin/pembeliantemp/${id}/update`
        this.axios
          .post(uriUpdatePembelian, {
            jumlah: this.transaksibelitemps[index].jumlah,
            harga_beli: this.transaksibelitemps[index].harga_beli,
            harga_beli_ppn: this.transaksibelitemps[index].harga_beli_ppn,
            ppn: ppns,
            datappn: this.transaksibelitemps[index].ppn,
          })
          .then(response => {})

        setTimeout(() => {
          this.getAllData()
          this.getTotal()
        }, 1000)
      } else {
        let ppns = 1
        this.transaksibelitemps.push({ jumlah: '', harga_beli: '', harga_beli_ppn: '' })
        let uriUpdatePembelian = process.env.VUE_APP_ROOT_API + `/api/superadmin/pembeliantemp/${id}/update`
        this.axios
          .post(uriUpdatePembelian, {
            jumlah: this.transaksibelitemps[index].jumlah,
            harga_beli: this.transaksibelitemps[index].harga_beli,
            harga_beli_ppn: this.transaksibelitemps[index].harga_beli_ppn,
            ppn: ppns,
            datappn: this.transaksibelitemps[index].ppn,
          })
          .then(response => {})

        setTimeout(() => {
          this.getAllData()
          this.getTotal()
        }, 1000)
      }
    },

    deleteAllPost() {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            let uriDeleteAll = process.env.VUE_APP_ROOT_API + `/api/superadmin/pembeliantemp/deleteall`
            this.axios
              .delete(uriDeleteAll)
              .then(response => {
                this.transaksibelitemps.splice(this.transaksibelitemps.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Transaksi pembelian deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, can not delete transaksi pembelian!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'Transaksi pembelian failed to deleted!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000,
                })
                setTimeout(() => {
                  this.getAllData()
                }, 1000)
              })
          }
        })
      setTimeout(() => {
        this.getAllData()
        this.getTotal()
      }, 1000)
    },

    deletePost(id, index) {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            this.transaksibelitemps.push({ id_transaksi_pembeliantemp: '' })
            let uriDelete = process.env.VUE_APP_ROOT_API + `/api/superadmin/pembeliantemp/${id}/delete`
            this.axios
              .delete(uriDelete)
              .then(response => {
                this.transaksibelitemps.splice(this.transaksibelitemps.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Transaksi pembelian deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, cann delete transaksi pembelian!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'transaksi pembelian failed to deleted!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 2000,
                })
              })

            setTimeout(() => {
              this.getAllData()
              this.getTotal()
            }, 1000)
          }
        })
    },

    doSearch() {
      const go = this.barang.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_barang.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },

    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.barang)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.barang.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(barang) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return barang.slice(from, to)
    },

    Pilihbarang(kode, nama_barang) {
      document.querySelector('#kode').setAttribute('value', kode)
      document.querySelector('#nama_barang').setAttribute('value', `${nama_barang}`)
      this.pembelian.kode = kode
      this.pembelian.nama_barang = nama_barang
    },
  },
  watch: {
    barang() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
